import { LandingPageContainer } from '../../components/landing-page-container.component';
import { type TemplateComponent } from '../template.types';
import { generalLanding } from './general-landing.css';

export const GeneralLandingTemplate: TemplateComponent = ({ content }) => {
	const {
		content: {
			fields: { seo, heading, sections }
		}
	} = content;

	return (
		<LandingPageContainer
			seo={seo}
			topContent={
				<div className="tc mv3">
					<h1 className="f2 fw4 mv1">{heading}</h1>
				</div>
			}>
			<div className={`flex flex-column ${generalLanding}`}>{content.render(sections)}</div>
		</LandingPageContainer>
	);
};
