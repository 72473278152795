import type { ContentRenderer, LandingPageContent, TypedContentOptions } from '../types/construct.types';

const WIDTH_CLASSES = {
	1: 'w-100',
	2: 'w-50',
	3: 'w-33',
	4: 'w-25',
	5: 'w-20',
	6: 'w-15'
};

type ItemsPerRow = keyof typeof WIDTH_CLASSES;

/**
 * Typequard to verify subject is a valid ItemsPerRow value.
 */
export function isItemsPerRow(subject: number): subject is ItemsPerRow {
	return subject in WIDTH_CLASSES;
}

/**
 * Generates a width class string based on the provided layout option.
 */
export function generateWidthClasses(itemsPerRow: ItemsPerRow): string {
	return WIDTH_CLASSES[itemsPerRow];
}

/**
 * Calculates the number of items per row based on the provided layout option.
 */
export function calculateItemsPerRow(options?: TypedContentOptions): ItemsPerRow {
	const layout = options?.CardContent?.layout;

	if (!layout || layout === 'hero') {
		return 1;
	} else if (layout === 'row-of-maximum') {
		return 6;
	} else {
		const itemsPerRow = parseInt(layout.split('-').pop() || '1', 10);
		return isItemsPerRow(itemsPerRow) ? itemsPerRow : 1;
	}
}

export function hasPageHeading(content: ContentRenderer<LandingPageContent>): boolean {
	const {
		content: {
			fields: { heading, media_image }
		}
	} = content;

	return !!heading && !!media_image?.image?.id;
}
