import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import { type FunctionComponent } from 'react';
import { COOKIE_LAST_PRODUCT, COOKIE_LAST_SEARCH } from '../../../constants/cookies';
import { PAGE_CONTENT_STYLE } from '../../../constants/general';
import { HOMEPAGE_LINK, SUPPORT_EMAIL_US_LINK, SUPPORT_FAQ_LINK } from '../../../constants/links';
import { useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useLiveChat } from '../../../hooks/analytics/live-chat/live-chat.hooks';
import { useContactInfo } from '../../../hooks/apollo/site/site.hooks';
import { TextButton } from '../../buttons';
import { StyledLink } from '../../common-components/link/styled-link.component';
import { Status } from '../../status';
import { SmsIcon, MailIcon, CallIcon } from '../../svg/icons.component';

type FourOhFourProps = {
	pageName?: string;
};

export const FourOhFour: FunctionComponent<FourOhFourProps> = ({ pageName = '404' }) => {
	useTrackPageView({ pageName: `${pageName}:404` });
	const {
		phone: { number: phoneNumber }
	} = useContactInfo();

	const openLiveChat = useLiveChat();

	const { getCookie } = useCookies();
	const lastProduct = getCookie(COOKIE_LAST_PRODUCT);
	const lastSearch = getCookie(COOKIE_LAST_SEARCH);

	return (
		<Status code={404}>
			<div className={`${PAGE_CONTENT_STYLE} page-container-border`}>
				<h2>We seem to have misplaced the page you're looking for.</h2>
				<div className="mt3 bb b--theme-grey-light" />
				<h3>
					<strong>Possible reasons for this error:</strong>
				</h3>
				<ul>
					<li>We moved the location of the page on our servers</li>
					<li>The bookmark you used is outdated</li>
					<li>The URL was entered incorrectly</li>
				</ul>
				<h3>
					<strong>Can't find what you're looking for?</strong>
				</h3>
				<p className="big">Try these other helpful links.</p>
				<ul>
					{lastSearch && (
						<li className="mt2">
							<StyledLink url={lastSearch} underlineHover={true}>
								View Last Search
							</StyledLink>
						</li>
					)}
					{lastProduct && (
						<li className="mt2">
							<StyledLink url={lastProduct} underlineHover={true}>
								View Last Product
							</StyledLink>
						</li>
					)}
					<li className="mt2">
						<StyledLink url={HOMEPAGE_LINK} underlineHover={true}>
							Visit Homepage
						</StyledLink>
					</li>
					<li className="mt2">
						<StyledLink url={SUPPORT_FAQ_LINK} underlineHover={true}>
							Read our FAQs
						</StyledLink>
					</li>
				</ul>
				<div className="mv3 bb b--theme-grey-light" />
				<div className="flex flex-column flex-row-ns">
					<StyledLink url={`tel:${phoneNumber}`} className="mt2" underline={true}>
						<div className="flex items-center mr3">
							<CallIcon className="f5 mr1" />
							<span>{phoneNumber}</span>
						</div>
					</StyledLink>
					<div className="mt2">
						<TextButton onClick={openLiveChat} underline={true}>
							<div className="flex items-center">
								<SmsIcon className="mr1" />
								<span className="mr3">Chat With Us</span>
							</div>
						</TextButton>
					</div>
					<StyledLink url={SUPPORT_EMAIL_US_LINK} className="mt2" underline={true}>
						<div className="flex items-center">
							<MailIcon className="f5 mr1" />
							<span>Email Us</span>
						</div>
					</StyledLink>
				</div>
			</div>
		</Status>
	);
};
