import { LandingPageContainer } from '../../components/landing-page-container.component';
import { SaleSectionList } from '../../components/sale-section-list.component';
import { type TemplateComponent } from '../template.types';

export const SavingsCenterTemplate: TemplateComponent = ({ content }) => {
	const {
		content: {
			fields: { seo, heading, subheading }
		}
	} = content;
	return (
		<LandingPageContainer
			seo={seo}
			topContent={
				<div className="tc mv3">
					<h1 className="f2 fw4 mv1">{heading}</h1>
					<h2 className="f4 fw3 mv1">{subheading}</h2>
				</div>
			}>
			<SaleSectionList content={content} />
		</LandingPageContainer>
	);
};
