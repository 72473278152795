import { type FunctionComponent } from 'react';
import { JumpTab, JumpTabs } from '../../../components/common-components/jump-tabs/jump-tabs.component';
import { type ContentRenderer, type LandingPageContent } from '../../../types/construct.types';
import { hasPageHeading } from '../../../utils/construct.utils';
import { PageHeading } from './page-heading.component';

export type SaleSectionListProps = {
	content: ContentRenderer<LandingPageContent>;
};

export const SaleSectionList: FunctionComponent<SaleSectionListProps> = ({ content }) => {
	const {
		content: {
			fields: { sale_section, heading, media_image }
		}
	} = content;

	return (
		<JumpTabs>
			{hasPageHeading(content) && <PageHeading heading={heading} mediaImage={media_image} />}
			{sale_section.items.map((section, index) => {
				const { nav_title = '' } = section.fields;
				return (
					<JumpTab key={index} tabName={nav_title} tabId={`section-tab-${index}`}>
						{content.render(section)}
					</JumpTab>
				);
			})}
		</JumpTabs>
	);
};
