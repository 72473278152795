import { LandingPageContainer } from '../../components/landing-page-container.component';
import { SaleSectionList } from '../../components/sale-section-list.component';
import { type TemplateComponent } from '../template.types';

export const DealsTemplate: TemplateComponent = ({ content }) => {
	const {
		content: {
			fields: { seo }
		}
	} = content;

	return (
		<LandingPageContainer seo={seo}>
			<SaleSectionList content={content} />
		</LandingPageContainer>
	);
};
