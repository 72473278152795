import { type FunctionComponent } from 'react';
import { FullWidthGraphImage } from '../../../components/images/graph-images/full-width-graph-image/full-width-graph-image.component';
import { type ImageContent, type TextContent } from '../../../types/construct.types';

export type PageHeadingProps = {
	heading: TextContent;
	mediaImage: ImageContent;
};

export const PageHeading: FunctionComponent<PageHeadingProps> = ({ heading, mediaImage }) => (
	<section>
		<div data-testid="heading-image">{mediaImage.image && <FullWidthGraphImage image={mediaImage.image} />}</div>
		<div className="tc">
			<p className="f3 b">{heading}</p>
		</div>
	</section>
);
